import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
// import FarmerIcon from "../images/farmer.png";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiOutlineSearch,
} from "react-icons/ai";
import UsersData from "./farmers/UsersData";
import useBreakpoint from "../utils/useBreakpoint";
import Skeleton from "./stats/Skeleton";

function TransactionTable() {
  const [farmersData, setFarmersData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [transactionData, setTransactionData] = useState([]);

  const navigate = useNavigate();

  const organizationId = useSelector(
    (state) => state?.auth?.user?.organizations?.[0]?._id
  );

  // console.log("organizationId", organizationId);

  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);

  const { isMobile, isExtremeSmall } = useBreakpoint();
  const { user } = useSelector((state) => state.auth);
  const contractsData = useSelector((state) => state?.auth?.contracts);

  // useEffect(() => {
  //   getUsers();
  // }, [offset]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  const getTransactionData = async () => {
    // console.log(contractsData?.greenToken?.address, "contractsData");
    const greenEndpoint = `https://api-testnet.polygonscan.com/api?module=account&action=tokentx&address=${user?.signer}&startblock=0&endblock=999999999&page=1&offset=5&sort=desc&apikey=key&UVIGQ1ISFVM3CE2TRUNXYAHWUVM2F9FB1V&contractaddress=${contractsData?.greenToken?.address} `;
    const blueEndpoint = `https://api-testnet.polygonscan.com/api?module=account&action=tokentx&address=${user?.signer}&startblock=0&endblock=999999999&page=1&offset=5&sort=desc&apikey=key&UVIGQ1ISFVM3CE2TRUNXYAHWUVM2F9FB1V&contractaddress=${contractsData?.blueToken?.address} `;

    try {
      let responses = [];
      let greenResponse = await axios.get(greenEndpoint);
      let blueResponse = await axios.get(blueEndpoint);
      if (
        !(greenResponse?.data.message === "NOTOK") &&
        !(blueResponse?.data.message === "NOTOK")
      ) {
        responses.push(
          ...greenResponse?.data?.result,
          ...blueResponse?.data?.result
        );
      }
      responses.sort((a, b) => b.timeStamp - a.timeStamp);
      setTransactionData(responses);
    } catch (error) {
      if (isLoggedIn) {
        toast.error(`${error.message}`);
      }
      // console.log(error);
    }
  };

  const searchItems = () => {
    if (searchInput !== "") {
      const filteredData = farmersData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(farmersData);
    }
  };

  useEffect(() => {
    searchItems();
  }, [searchInput]);

  useEffect(() => {
    getTransactionData();
  }, [contractsData]);

  // console.log(transactionData, "Transaction");

  return (
    <div className="col-span-4 xl:col-span-4 bg-white shadow-lg border border-slate-200 mt-10 rounded-3xl">
      <header
        className={
          isMobile
            ? "px-5 py-4 pt-10 border-b border-slate-100 flex flex-col"
            : "px-5 py-4 pt-10 border-b border-slate-100 flex justify-between"
        }
      >
        <div className={isMobile ? "flex justify-between" : ""}>
          <h1 className="text-2xl mb-1 font-semibold text-gray-900">Modules</h1>
        </div>

        <div
          className={
            isMobile ? "flex flex-col items-center" : "flex items-center"
          }
        >
          <div className={isMobile ? "flex w-[17rem] mt-5" : "flex w-[17rem]"}>
            {farmersData?.length > 0 && (
              <div className=" flex items-center justify-center pl-1 pr-4 border border-[#008542] rounded-md">
                <input
                  type="text"
                  id="name"
                  name="name"
                  // value={values.name}
                  onChange={(e) => setSearchInput(e.target.value)}
                  // onBlur={handleBlur}
                  autoComplete="given-name"
                  className="border-none focus:border-none focus:outline-none focus:ring-0"
                />
                <AiOutlineSearch className="text-[#008542]" />
              </div>
            )}
          </div>
          {/* Add New button */}
        </div>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Module ID</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Module Number</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Status</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-slate-100">
              {/* Row */}

              <UsersData transactionData={transactionData} />
            </tbody>
          </table>
        </div>
      </div>
      <div>
        {farmersData?.length > 0 && (
          <div className="App">
            <ReactPaginate
              previousLabel={
                <span className="flex items-center">
                  {!isExtremeSmall && <AiOutlineArrowLeft />} &nbsp; Prev
                </span>
              }
              nextLabel={
                <span className="flex items-center">
                  Next &nbsp; {!isExtremeSmall && <AiOutlineArrowRight />}
                </span>
              }
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TransactionTable;
