// Breakpoints
export const XXS = 0;
export const XS = 320;
export const SM = 480;
export const MD = 768;
export const LG = 1024;
export const XL = 1280;
export const XXL = 1600;
export const XXXL = 1920;
export const XXXXL = 2560;
