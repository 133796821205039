import React from "react";
import CoinAnimation from "./coin-animation.json";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useBreakpoint from "../../utils/useBreakpoint";
import Lottie from "lottie-react";
import Module from "../../images/module.png";
import CoinGreen from "../../images/Carbo-X-Green.svg";

function WelcomeCard() {
  const organizationName = useSelector(
    (state) => state?.auth?.user?.organizations?.[0]?.name
  );

  console.log(organizationName);

  const { isMobile, isTable } = useBreakpoint();

  return (
    <div
      style={
        isMobile
          ? {
              backgroundImage:
                "linear-gradient(315deg,rgba(171, 49, 116, 1) 0%, rgba(41, 104, 178, 1) 74%)",
              // linear-gradient(
              //   90deg,
              //   rgba(41, 104, 178, 1) 0%,
              //   rgba(72, 102, 176, 1) 35%,
              //   rgba(171, 49, 116, 1) 100%
              // )
              height: "25rem",
            }
          : {
              backgroundImage:
                "linear-gradient(315deg,rgba(171, 49, 116, 1) 24%, rgba(41, 104, 178, 1) 74%)",
              height: "28rem",
            }
      }
      className="flex flex-col shadow-md mt-5 items-center rounded-3xl mb-5 bg-white md:flex-row md:max-w-3xl  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
    >
      <div
        style={
          isMobile
            ? { marginTop: "-2rem" }
            : { marginTop: "-2rem", marginLeft: "3rem" }
        }
        class="flex flex-col justify-between pt-2  leading-normal"
      >
        <h5
          class={
            isMobile
              ? "mb-2 mt-12 text-center text-xl md:text-3xl font-bold tracking-tight text-white"
              : "mb-2 text-xl md:text-3xl font-bold tracking-tight text-white"
          }
        >
          Welcome
          <br /> to solar Module app
        </h5>
        <p
          class={
            isMobile
              ? "mb-3 px-8 mr-5 text-center font-normal text-white"
              : "mb-3 font-normal text-white"
          }
        >
          This app will help you to see the module lifecycle events
        </p>
        <div className={isMobile ? "flex justify-center" : "justify-end"}>
          <Link
            type="submit"
            to={`/modules`}
            className="w-max flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium text-[#000] bg-[#fff] shadow-lg  hover:bg-[rgb(171, 49, 116)]  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00AB55]"
          >
            Show Modules
          </Link>
        </div>
      </div>
      {/* <img
        style={isMobile ? { width: "180px" } : {}}
        src={WelcomeSvg}
        alt="Welcome"
        className={isMobile ? "w-72 mt-8 p-5" : "w-72 p-5"}
      /> */}
      {/* <div className={isMobile ? "w-96 mt-8 p-5" : "w-96 p-5"}>
        <Lottie animationData={CoinAnimation} loop={true} />
      </div> */}
      <div className={isMobile ? "mt-8 flex relative" : "relative flex"}>
        <div className="">
          <img
            style={
              isMobile
                ? {
                    width: "180px",
                    filter: "drop-shadow(rgba(0, 0, 0, 0.4) 2px 5px 12px)",
                  }
                : { filter: "drop-shadow(rgba(0, 0, 0, 0.4) 2px 5px 12px)" }
            }
            src={Module}
            alt="Welcome"
            className={isMobile ? "mt-8 p-5" : "w-72 p-5"}
          />
        </div>
      </div>
    </div>
  );
}

export default WelcomeCard;
