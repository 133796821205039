import { useState, useEffect } from "react";
import LineChart from "../../charts/LineChart01";
import Icon from "../../images/icon-01.svg";
// import EditMenu from "../EditMenu";
import { GiFarmer } from "react-icons/gi";
import { GiPlantRoots } from "react-icons/gi";
import { HiOutlineUsers } from "react-icons/hi";
import { GiToken } from "react-icons/gi";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import { AiOutlineTransaction, AiFillDollarCircle } from "react-icons/ai";
import { BiCoinStack } from "react-icons/bi";
import { FaCoins } from "react-icons/fa";
import { MdViewModule } from "react-icons/md";
import millify from "millify";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../utils/Utils";
import useBreakpoint from "../../utils/useBreakpoint";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import Skeleton from "./Skeleton";

function Stat1() {
  const [farmData, setFarmData] = useState([]);
  const [plotData, setPlotData] = useState([]);
  const [farmersData, setFarmersData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [greenTokenDetails, setGreenTokenDetails] = useState({
    name: "N/A",
    symbol: "N/A",
    tokensAvailable: "N/A",
    tokenPrice: "N/A",
    circulating: "N/A",
    totalTokens: "N/A",
  });
  const [blueTokenDetails, setBlueTokenDetails] = useState({
    name: "N/A",
    symbol: "N/A",
    tokensAvailable: "N/A",
    tokenPrice: "N/A",
    circulating: "N/A",
    totalTokens: "N/A",
  });

  const { isMobile, isTable } = useBreakpoint();

  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);

  const { user } = useSelector((state) => state.auth);
  const contractsData = useSelector((state) => state?.auth?.contracts);

  const getTokenDetails = async () => {
    console.log("function called");
    if (user?.signer) {
      let blueDetails = {
        name: await contractsData?.blueToken.name(),
        symbol: await contractsData?.blueToken.symbol(),
        tokensAvailable: ethers.utils.formatEther(
          (await contractsData?.blueToken.balanceOf(user?.signer)) || "0"
        ),
        tokenPrice: "N/A",
        circulating: millify(
          ethers.utils.formatEther(
            (await contractsData?.blueToken.totalSupply()) || "0"
          )
        ),
        totalTokens: millify(
          ethers.utils.formatEther(
            (await contractsData?.blueToken.totalSupply()) || "0"
          )
        ),
      };
      setBlueTokenDetails(blueDetails);
      let greenDetails = {
        name: await contractsData?.greenToken.name(),
        symbol: await contractsData?.greenToken.symbol(),
        tokensAvailable: ethers.utils.formatEther(
          (await contractsData?.greenToken.balanceOf(user?.signer)) || "0"
        ),
        tokenPrice: "N/A",
        circulating: ethers.utils.formatEther(
          (await contractsData?.greenToken.totalSupply()) || "0"
        ),
        totalTokens: `∞`,
      };
      setGreenTokenDetails(greenDetails);
    }
  };

  useEffect(() => {
    if (user?.signer) {
      getTokenDetails();
    }
  }, [user?.signer, contractsData]);

  console.log(greenTokenDetails, blueTokenDetails);

  return (
    <div
      style={
        isMobile
          ? { width: "100%", marginTop: "20px" }
          : { width: "320px", marginTop: "20px" }
      }
      className="bg-white shadow-lg mb-5 rounded-3xl border border-slate-200"
    >
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          {/* Icon */}
          {/* Menu button */}
          {/* <EditMenu className="relative inline-flex">
            <li>
              <Link
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                to="#0"
              >
                Option 1
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                to="#0"
              >
                Option 2
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3"
                to="#0"
              >
                Remove
              </Link>
            </li>
          </EditMenu> */}
        </header>
        <h2
          className={
            isMobile
              ? "text-lg text-center mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
              : "text-lg mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
          }
        >
          Total Modules
        </h2>
        {/* <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
          Users
        </div> */}
        <div
          className={
            isMobile ? "flex items-center justify-center" : "flex items-start"
          }
        >
          <div className="flex">
            <div className="text-4xl flex font-bold text-slate-800 mr-2">
              <MdViewModule
                style={{
                  backgroundImage:
                    "linear-gradient(120deg, #83EAF1 0%, #63A4FF 100%)",
                  width: "45px",
                  height: "45px",
                  padding: "6px",
                  borderRadius: "15px",
                }}
                className="text-blue-800"
              />{" "}
              <span className="ml-6">250</span>
              <span className="text-lg mt-4 ml-1"> </span>
            </div>
          </div>
        </div>
        <h2
          className={
            isMobile
              ? "text-lg text-center mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
              : "text-lg mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
          }
        >
          Modules that are recycled
        </h2>
        {/* <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
          Users
        </div> */}

        <div
          className={
            isMobile ? "flex items-center justify-center" : "flex items-start"
          }
        >
          <div className="flex">
            <div className="text-4xl flex font-bold text-slate-800 mr-2">
              <MdViewModule
                style={{
                  background: "#FFA500",
                  width: "45px",
                  height: "45px",
                  padding: "6px",
                  borderRadius: "15px",
                }}
                className="text-orange-800"
              />{" "}
              <span className="ml-5">700</span>
              <span className="text-lg mt-4 ml-1"></span>
            </div>
          </div>
          {/* <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">
            +49%
          </div> */}
        </div>
        <h2
          className={
            isMobile
              ? "text-lg text-center mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
              : "text-lg mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
          }
        >
          Reuseable Modules
        </h2>
        <div
          className={
            isMobile ? "flex items-center justify-center" : "flex items-start"
          }
        >
          <div className="flex">
            <div className="text-4xl flex font-bold text-slate-800 mr-2">
              <MdViewModule
                style={{
                  backgroundImage:
                    "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)",
                  width: "45px",
                  height: "45px",
                  padding: "6px",
                  borderRadius: "15px",
                }}
                className="text-green-800"
              />{" "}
              <span className="ml-5">300</span>
              <span className="text-lg mt-4 ml-1"> </span>
            </div>
          </div>
          {/* <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">
            +49%
          </div> */}
        </div>
        <h2
          className={
            isMobile
              ? "text-lg text-center mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
              : "text-lg mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
          }
        >
          Disposable Modules
        </h2>
        {/* <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
          Users
        </div> */}
        <div
          className={
            isMobile ? "flex items-center justify-center" : "flex items-start"
          }
        >
          <div className="flex">
            <div className="text-4xl mb-5 flex font-bold text-slate-800 mr-2">
              <MdViewModule
                style={{
                  background: "crimson",
                  width: "45px",
                  height: "45px",
                  padding: "6px",
                  borderRadius: "15px",
                }}
                className="text-red-800"
              />{" "}
              <span className="ml-5">100</span>
              <span className="text-lg mt-4 ml-1"></span>
            </div>
          </div>
        </div>
        {/* <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">
            +49%
          </div> */}
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        {/* <LineChart data={chartData} width={389} height={128} /> */}
      </div>
    </div>
  );
}

export default Stat1;
