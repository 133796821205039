import React from "react";
import Module from "../images/solar.jpg";

function LifeCycle() {
  const TIMELINE_DATA = [
    {
      title: "Production",
      subtitle: "At Production Plant",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      img: Module,
    },
    // {
    //   title: "Frame Inspection",
    //   description:
    //     "Honey formation on frames are checked every 10-15 days for better yield.",
    //   img: "/assets/img/retail.jpg",
    // },
    {
      title: "Sale",
      subtitle: "At Warehouse",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      img: Module,
    },
    {
      title: "Transport",
      subtitle: "to rajkot",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      img: Module,
    },
    {
      title: "Installation",
      subtitle: "Rajkot, GJ",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      img: Module,
    },
  ];

  return (
    <div className="ag-timeline-block">
      <div className="ag-timeline_title-box">
        <div className="ag-timeline_title">
          Here is the lifecycle events of your modules
        </div>
        {/* <div className="mt-2 mb-2 ag-timeline_title-para text-justify">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
      cupiditate quia ad fuga fugiat.
    </div> */}
        {/* <div className="ag-timeline_tagline">Trace</div> */}
      </div>
      <section className="ag-section">
        <div className="ag-format-container">
          <div className="js-timeline ag-timeline">
            <div className="js-timeline_line ag-timeline_line">
              <div className="js-timeline_line-progress ag-timeline_line-progress"></div>
            </div>
            <div className="ag-timeline_list">
              {TIMELINE_DATA.map((item, i) => (
                <div class="js-timeline_item js-ag-show ag-timeline_item">
                  <div class="ag-timeline-card_item">
                    <div class="ag-timeline-card_inner">
                      <div class="ag-timeline-card_img-box"></div>
                      <div class="ag-timeline-card_info">
                        <div class="ag-timeline-card_meta">
                          {item?.title}
                          {<div class="subtitle">{item?.subtitle}</div>}
                        </div>
                        <div class="ag-timeline-card_desc mb-4 text-justify">
                          {item?.description}
                        </div>
                        {/* {
                      <a
                        href="https://honeyveda.in/"
                        class="card-btn btn-primary mt-1"
                      >
                        Visit Website
                      </a>
                    } */}
                      </div>
                    </div>
                    <div class="ag-timeline-card_arrow"></div>
                  </div>
                  <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                    <div class="ag-timeline-card_point">
                      <img
                        src={item?.img}
                        class="ag-timeline-card_img"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="ag-timeline-card_meta-box"></div>
                </div>
              ))}
              {/* <div className="Thanks-content mt-1 text-center">
            <h1>Thank you for purchase!</h1>
          </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LifeCycle;
