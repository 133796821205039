import React from "react";
// import FarmerIcon from "../../images/farmer.png";
import { Link, useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import moment from "moment";

function UsersData({ transactionData }) {
  const navigate = useNavigate();
  return (
    <>
      <tr
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/module-event")}
      >
        <td className="p-2">
          <div className="flex items-center">
            <div className="d-block">
              <div className="text-slate-800">1246785</div>
            </div>
          </div>
        </td>
        <td className="p-2">
          <div className="d-block">
            <div className="text-center">98756586569</div>
            {/* <div className="text-sm text-gray-500">
                {data.department}
              </div> */}
          </div>
        </td>
        <td className="p-2">
          <div className="d-block overflow-hidden truncate w-40">
            <span
              class={`p-2 inline-flex text-sm leading-5 font-semibold rounded-lg ${"bg-green-100 text-green-800"}`}
            >
              Reusable
            </span>
          </div>
        </td>
      </tr>
      <tr
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/module-event")}
      >
        <td className="p-2">
          <div className="flex items-center">
            <div className="d-block">
              <div className="text-slate-800">1246785</div>
            </div>
          </div>
        </td>
        <td className="p-2">
          <div className="d-block">
            <div className="text-center">98756586569</div>
            {/* <div className="text-sm text-gray-500">
                {data.department}
              </div> */}
          </div>
        </td>
        <td className="p-2">
          <div className="d-block overflow-hidden truncate w-40">
            <span
              class={`p-2 inline-flex text-sm leading-5 font-semibold rounded-lg ${"bg-orange-100 text-orange-800"}`}
            >
              Recycled
            </span>
          </div>
        </td>
      </tr>
      <tr
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/module-event")}
      >
        <td className="p-2">
          <div className="flex items-center">
            <div className="d-block">
              <div className="text-slate-800">1246785</div>
            </div>
          </div>
        </td>
        <td className="p-2">
          <div className="d-block">
            <div className="text-center">98756586569</div>
            {/* <div className="text-sm text-gray-500">
                {data.department}
              </div> */}
          </div>
        </td>
        <td className="p-2">
          <div className="d-block overflow-hidden truncate w-40">
            <span
              class={`p-2 inline-flex text-sm leading-5 font-semibold rounded-lg ${"bg-red-100 text-red-800"}`}
            >
              Disposable
            </span>
          </div>
        </td>
      </tr>
      <tr
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/module-event")}
      >
        <td className="p-2">
          <div className="flex items-center">
            <div className="d-block">
              <div className="text-slate-800">1246785</div>
            </div>
          </div>
        </td>
        <td className="p-2">
          <div className="d-block">
            <div className="text-center">98756586569</div>
            {/* <div className="text-sm text-gray-500">
                {data.department}
              </div> */}
          </div>
        </td>
        <td className="p-2">
          <div className="d-block overflow-hidden truncate w-40">
            <span
              class={`p-2 inline-flex text-sm leading-5 font-semibold rounded-lg ${"bg-orange-100 text-orange-800"}`}
            >
              Recycled
            </span>
          </div>
        </td>
      </tr>
      <tr
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/module-event")}
      >
        <td className="p-2">
          <div className="flex items-center">
            <div className="d-block">
              <div className="text-slate-800">1246785</div>
            </div>
          </div>
        </td>
        <td className="p-2">
          <div className="d-block">
            <div className="text-center">98756586569</div>
            {/* <div className="text-sm text-gray-500">
                {data.department}
              </div> */}
          </div>
        </td>
        <td className="p-2">
          <div className="d-block overflow-hidden truncate w-40">
            <span
              class={`p-2 inline-flex text-sm leading-5 font-semibold rounded-lg ${"bg-green-100 text-green-800"}`}
            >
              Reusable
            </span>
          </div>
        </td>
      </tr>
      <tr
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/module-event")}
      >
        <td className="p-2">
          <div className="flex items-center">
            <div className="d-block">
              <div className="text-slate-800">1246785</div>
            </div>
          </div>
        </td>
        <td className="p-2">
          <div className="d-block">
            <div className="text-center">98756586569</div>
            {/* <div className="text-sm text-gray-500">
                {data.department}
              </div> */}
          </div>
        </td>
        <td className="p-2">
          <div className="d-block overflow-hidden truncate w-40">
            <span
              class={`p-2 inline-flex text-sm leading-5 font-semibold rounded-lg ${"bg-red-100 text-red-800"}`}
            >
              Disposable
            </span>
          </div>
        </td>
      </tr>
    </>
  );
}

export default UsersData;
