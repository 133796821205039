import React, { useEffect } from "react";
import { Formik, replace } from "formik";
import { useState, useRef } from "react";
// import * as Yup from "yup";
import { baseUrl } from "../../utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { boolean } from "yup";
import useBreakpoint from "../../utils/useBreakpoint";
import * as Yup from "yup";
import CoinBlue from "../../images/Carbo-X-Blue.svg";
import CoinGreen from "../../images/Carbo-X-Green.svg";
import { BsCheckLg } from "react-icons/bs";
import BlueTokenAbi from "../../abis/CarboXBlue.json";
import GreenTokenAbi from "../../abis/CarboXGreen.json";
import { errors, ethers } from "ethers";

function BuyTokenPage({ setShowFarmform, setShowUserform, setUserId }) {
  const [isContact, setIsContact] = useState(false);
  const [isAddress, setIsAdress] = useState(false);
  const [isType, setIstype] = useState(false);
  const [file, setFile] = useState(null);
  const [imageData, setImageData] = useState(null);
  const fileInputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("green");
  const [greenTokenAmount, setGreenTokenAmount] = useState("");
  const [blueTokenAmount, setBlueTokenAmount] = useState("");
  const [error, setError] = useState("");
  const [checked, setChecked] = useState(false);

  const organizationId = useSelector(
    (state) => state?.auth?.user?.organizations?.[0]?._id
  );

  const { user } = useSelector((state) => state.auth);
  const contractsData = useSelector((state) => state?.auth?.contracts);

  const navigate = useNavigate();

  const { isMobile, isTable } = useBreakpoint();

  const handleImgInput = async (e) => {
    const file = {
      url: URL.createObjectURL(e.target.files[0]),
      details: e.target.files[0],
    };
    console.log(file);
    setFile(file);
  };

  const uploadToCdn = async (file) => {
    let formData = new FormData();
    formData.append("item", file?.details);
    const endPoint = "https://media.agrotrust.io/content/";
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(endPoint, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      });
      // setImageData(response.data);
      return response.data?.result?.url;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCardSelect = (color) => {
    setSelected(color);
  };

  // const checkError = () => {
  //   if (greenTokenAmount === undefined || !greenTokenAmount?.length) {
  //     setError("Please Enter CarboX Green Token");
  //     return;
  //   }

  //   if (blueTokenAmount === undefined || !blueTokenAmount?.length) {
  //     setError("Please Enter CarboX Blue Token");
  //     return;
  //   }
  // };

  // const handleGreenInput = (e) => {
  //   setGreenTokenAmount(e.target.value);
  // };

  // const handleBlueInput = (e) => {
  //   setBlueTokenAmount(e.target.value);
  // };

  const BuyToken = async (tokenType) => {
    console.log(tokenType, greenTokenAmount, blueTokenAmount);
    let formattedGreenAmount = greenTokenAmount
      ? ethers.utils.parseEther(greenTokenAmount)
      : "";
    let formattedBlueAmount = blueTokenAmount
      ? ethers.utils.parseEther(blueTokenAmount)
      : "";

    console.log(formattedGreenAmount, formattedBlueAmount);

    // checkError();

    toast.promise(
      new Promise(async (resolve, reject) => {
        setLoading(true);
        try {
          if (user?.signer && tokenType === "green") {
            let buyTransaction = await contractsData?.greenToken?.transferFrom(
              "0x100dF25e6beF94d5c82e1C411DF05E0536130BFf",
              user?.signer,
              formattedGreenAmount
            );
            await buyTransaction.wait();
            setLoading(false);
            resolve(buyTransaction);
            // window.location.reload(false);
          }
          if (user?.signer && tokenType === "blue") {
            let buyTransaction = await contractsData?.blueToken.transferFrom(
              "0x100dF25e6beF94d5c82e1C411DF05E0536130BFf",
              user?.signer,
              formattedBlueAmount
            );
            await buyTransaction.wait();
            setLoading(false);
            resolve(buyTransaction);
            // window.location.reload(false);
          }
        } catch (error) {
          setLoading(false);
          reject(error);
          console.log(error);
        }
      }),
      {
        pending: "Buying Token...",
        success: "Token Purchased successfully 👌",
        error: "Error Purchasing Token 🤯",
      }
    );
  };

  return (
    <form
      // onSubmit={handleSubmit}
      className="space-y-8 divide-y divide-gray-200 "
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <h3 className="text-lg pt-4 leading-6 uppercase font-semibold text-gray-900">
              Buy Tokens
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              We have two tokens you can buy
            </p>
          </div>

          <div>
            <h3 className="text-lg pt-4 text-center leading-6 uppercase font-semibold text-gray-900">
              Select Token You Want to Buy
            </h3>
          </div>

          <div className="mt-6 flex justify-center">
            <div
              onClick={() => handleCardSelect("green")}
              style={
                isMobile
                  ? { width: "100%", margin: "20px  10px" }
                  : { width: "300px", margin: "20px 10px" }
              }
              className={`bg-white ${
                selected === "green" &&
                "border-2 border-green-500 shadow-green-200"
              } shadow-lg mb-5 rounded-3xl border border-slate-200 cursor-pointer transition-all`}
            >
              <div
                className={`${
                  selected === "green" && "bg-green-500 border-green-500"
                } border-2 rounded-full w-6 h-6 mt-4 ml-4 flex items-center cursor-pointer justify-center`}
              >
                <BsCheckLg
                  className={`text-white ${
                    selected === "green" ? "" : "hidden"
                  }`}
                />
              </div>
              <img
                style={
                  isMobile
                    ? {
                        width: "180px",
                      }
                    : {}
                }
                src={CoinGreen}
                alt="Welcome"
                className={isMobile ? "w-72 mt-8 p-5" : "w-72 p-5"}
              />
              <div className="px-5 text-center pb-5 pt-5">
                <h2>CarboX Green</h2>
              </div>
              {/* Chart built with Chart.js 3 */}
              <div className="grow">
                {/* Change the height attribute to adjust the chart height */}
                {/* <LineChart data={chartData} width={389} height={128} /> */}
              </div>
            </div>
            <div
              onClick={() => handleCardSelect("blue")}
              style={
                isMobile
                  ? { width: "100%", margin: "20px  10px" }
                  : { width: "300px", margin: "20px  10px" }
              }
              className={`bg-white ${
                selected === "blue" &&
                "border-2 border-blue-500 shadow-blue-200"
              } shadow-lg mb-5 rounded-3xl border border-slate-200 cursor-pointer transition-all`}
            >
              <div
                className={`${
                  selected === "blue" && "bg-blue-500 border-blue-500"
                } border-2 rounded-full w-6 h-6 mt-4 ml-4 flex items-center  justify-center`}
              >
                <BsCheckLg
                  className={`text-white ${
                    selected === "blue" ? "" : "hidden"
                  }`}
                />
              </div>
              <img
                style={
                  isMobile
                    ? {
                        width: "180px",
                      }
                    : {}
                }
                src={CoinBlue}
                alt="Welcome"
                className={isMobile ? "w-72 mt-8 p-5" : "w-72 p-5"}
              />
              <div className="px-5 text-center pb-5  pt-5">
                <h2>CarboX Blue</h2>
              </div>
              {/* Chart built with Chart.js 3 */}
              <div className="grow">
                {/* Change the height attribute to adjust the chart height */}
                {/* <LineChart data={chartData} width={389} height={128} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8">
          <div>
            <h3 className="text-lg text-center leading-6 font-medium text-gray-900">
              BUY {selected === "green" ? "CARBOX GREEN" : "CARBOX"} TOKEN
            </h3>
          </div>
          <div className="mt-6 mb-6 flex justify-center items-center">
            <div className="sm:col-span-2">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Enter {selected === "green" ? "CARBOX GREEN" : "CARBOX"} token
              </label>
              <div className="mt-1 flex items-center">
                <div>
                  {selected === "green" ? (
                    <div>
                      {" "}
                      <input
                        type="number"
                        value={greenTokenAmount}
                        onChange={(e) => setGreenTokenAmount(e.target.value)}
                        autoComplete="given-name"
                        className={
                          error === "Please Enter CarboX Green Token"
                            ? "shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-red-500 focus:border-red-500"
                            : "shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-l-md"
                        }
                      />
                      {error === "Please Enter CarboX Green Token" && (
                        <div className="text-red-500">{error}</div>
                      )}{" "}
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <input
                        type="number"
                        value={blueTokenAmount}
                        onChange={(e) => setBlueTokenAmount(e.target.value)}
                        autoComplete="given-name"
                        className={
                          error === "Please Enter CarboX Blue Token"
                            ? "shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-red-500 focus:border-red-500"
                            : "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-l-md"
                        }
                      />
                      {error === "Please Enter CarboX Blue Token" && (
                        <div className="text-red-500">{error}</div>
                      )}
                    </div>
                  )}
                </div>
                <div className="">
                  {selected === "green" ? (
                    <button
                      disabled={checked ? false : true}
                      onClick={() => BuyToken("green")}
                      type="button"
                      className={`btn ${
                        selected === "green" ? "bg-[#00AB55]" : "bg-blue-500"
                      } disabled:bg-green-300 disabled:cursor-not-allowed shadow-lg shadow-green-500/50 hover:bg-[#00AB55] text-white !rounded-r-md !rounded-l-none`}
                      // disabled={loading ? true : false}
                    >
                      <span className="">{loading ? "Buying..." : "BUY"}</span>
                    </button>
                  ) : (
                    <button
                      disabled={checked || loading ? false : true}
                      onClick={() => BuyToken("blue")}
                      type="button"
                      className={`btn ${
                        selected === "green" ? "bg-[#00AB55]" : "bg-blue-500"
                      }  shadow-lg disabled:bg-blue-300 disabled:cursor-not-allowed shadow-green-500/50 text-white !rounded-r-md !rounded-l-none`}
                    >
                      <span className="">{loading ? "Buying..." : "BUY"}</span>
                    </button>
                  )}
                </div>
              </div>
              <div className="flex mt-2 items-center">
                <input
                  checked={checked}
                  onClick={() => setChecked((prev) => !prev)}
                  type={"checkbox"}
                  className="mr-2 cursor-pointer"
                />
                <h4>
                  I agree to{" "}
                  <a
                    href="https://carbox-landing.netlify.app/terms&condition.html"
                    rel="noreferrer"
                    target={"_blank"}
                    className="text-blue-500"
                  >
                    terms and conditions
                  </a>
                </h4>
              </div>
            </div>
          </div>
          {/* <h2 className="text-center">Pay With</h2>
          <div className="flex justify-center items-center mb-5">
            <div className="">
              <button
                type="button"
                style={{ marginTop: "25px" }}
                className={`btn ${
                  selected === "green" ? "bg-[#00AB55]" : "bg-blue-500"
                }  shadow-lg shadow-green-500/50 hover:bg-[#00AB55] text-white`}
                disabled={loading ? true : false}
              >
                <span className="">{"CRYPTO"}</span>
              </button>
            </div>
            <h2 className="mt-5 ml-3 mr-3">OR</h2>
            <div className="mr-2">
              <button
                type="button"
                style={
                  selected === "green"
                    ? {
                        marginTop: "25px",
                        backgroundColor: "#ffff",
                        border: "1px solid #00AB55",
                      }
                    : {
                        marginTop: "25px",
                        backgroundColor: "#ffff",
                        border: "1px solid blue",
                      }
                }
                className={`btn ${
                  selected === "green"
                    ? "bg-[#00AB55] text-[#00AB55]"
                    : "bg-blue-500 text-blue-500"
                }  shadow-lg shadow-green-500/50 hover:bg-[#00AB55] `}
                disabled={loading ? true : false}
              >
                <span className="">{"PAYU"}</span>
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </form>
  );
}

export default BuyTokenPage;
