import React from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
// import MainLogo from "../images/agro-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { contracts, login, logout } from "../redux/actions/auth";
import { useNavigate } from "react-router-dom";
import { IoWalletSharp } from "react-icons/io5";
import { ethers } from "ethers";
import BlueTokenAbi from "../abis/CarboXBlue.json";
import GreenTokenAbi from "../abis/CarboXGreen.json";
import { useEffect } from "react";
import { toast } from "react-toastify";

function TopBar({ setSidebarOpen }) {
  // const userNavigation = [{ name: "Sign out", href: "/signin" }];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleLogout = () => {
  //   dispatch(logout());
  //   navigate("/signin", { replace: true });
  // };

  // const loadContracts = async () => {
  //   let provider;
  //   let signer;

  //   provider = new ethers.providers.Web3Provider(window.ethereum);
  //   await provider.send("eth_requestAccounts", []);
  //   signer = provider.getSigner();

  //   // dispatch(providerDispatch(provider));

  // };

  return (
    <div
      style={{ background: "rgb(255 255 255 / 0.5)" }}
      className="sticky top-0 z-10 flex-shrink-0 flex h-16 shadow"
    >
      {/* <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button> */}
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex"></div>
        <div className="ml-4 flex items-center md:ml-6">
          <Menu as="div" className="ml-3 relative">
            <div></div>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
